import {Component, DestroyRef, inject, OnInit} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {DialogRef} from "@angular/cdk/dialog";
import {ShopService} from "@shared/services/shop.service";
import {debounceTime, finalize, fromEvent, map, switchMap} from "rxjs";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NotFoundComponent} from "@shared/components/not-found/not-found.component";
import {SharedModule} from "@shared/shared.module";
import {environment} from "@environments/environment";

export interface shopList {
  name: string
  src: string
  id: string
  syncProvider: any
  logoUrl: string
}

@Component({
  selector: 'sho-modal-shop-selector',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule, NotFoundComponent, SharedModule],
  templateUrl: './modal-shop-selector.component.html',
  styleUrls: ['./modal-shop-selector.component.scss']
})
export class ModalShopSelectorComponent implements OnInit {

  private readonly dialogRef = inject(DialogRef)
  private readonly shopService = inject(ShopService)
  private readonly _destroyRef = inject(DestroyRef)

  protected shops!: shopList[]
  protected allShops!: shopList[]
  protected shopSearch!: string
  protected isLoading: boolean = false

  ngOnInit() {
    this._getShops()
    this._searchFilter()
  }

  public selectShop(shopId: string): void {
    this.dialogRef.close(shopId)
  }

  private _getShops(): void {
    this.isLoading = true
    this.shopService.getShops()
      .pipe(
        map(res => res.map(val => (
          {
            ...val,
            src: val.logoUrl ?
              `https://${environment.firebaseConfig.storageBucket}/${val.id}/tienda/${val.logoUrl}` :
              '../../../../../assets/images/Logo soho Seller.png'
          }))),
        finalize(() => this.isLoading = false),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(res => this.shops = this.allShops = res)
  }

  private _searchFilter(): void {
    const input = document.getElementById('search') as HTMLElement;
    const inputEvent$ = fromEvent(input, 'keyup').pipe(
      map((event) => (event.target as HTMLInputElement).value)
    )

    inputEvent$.pipe(
      debounceTime(300),
      switchMap(value =>
        this.shops = value ? this.shops.filter(val => val.name.toLowerCase().includes(value.toLowerCase())) : this.allShops
      )
    ).pipe(takeUntilDestroyed(this._destroyRef)).subscribe()
  }

}
