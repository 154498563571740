import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { map, Observable, of, tap } from "rxjs";
import { shopList } from "@shared/components/modal-shop-selector/modal-shop-selector.component";
import { environment } from "@environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ShopService {

  private shops: shopList[] = []

  protected http = inject(HttpClient)

  getShops(force: boolean = false): Observable<shopList[]> {
    return (this.shops.length && !force) ?
      of(this.shops) :
      this.http.get<{ success: boolean, data: shopList[] }>(this._getShopsUrl()).pipe(
        map(res => res.data),
        tap(res => { this.shops = res })
      )
  }

  private _getShopsUrl(): string {
    return `${environment.baseURL}${environment.endpoints.shop}`
  }
}
