<div class="w-screen h-screen bg-white sm:bg-white relative flex flex-col">
  <app-logo-navbar-soho></app-logo-navbar-soho>
  <div class="bg-white">
    <div id="controls-carousel" class="relative " data-carousel="static">
      <div *ngIf="!isLoading; else loading" class="relative overflow-hidden flex flex-col mt-[60px] items-center w-full">
        <!-- Item 1 -->
        <ng-container *ngIf="currentSlide === 1">
          <div class="text-center w-[440px]">
            <p class=" font-bold md:text-2xl text-sm sm:text-lg leading-normal">Te damos la bienvenida a la plataforma
              Back
              office de Soho</p>
            <p class="font-semibold text-sm sm:text-lg mt-[20px] ">Aquí podrás publicar tus productos, gestionar tus
              ventas y potenciar tu tienda hacia nuevos horizontes</p>
            <div class="flex justify-center mt-[40px]">
              <img src="../../../../assets/svg/Group Carrito onboarding.svg" alt="">
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="currentSlide === 2">
          <!-- Item 2 -->
          <div class="text-center w-[440px]">
            <p class="font-bold lg:text-2xl text-sm sm:text-lg leading-normal">Te damos la bienvenida a la
              plataforma Back office de Soho</p>
            <p class="font-semibold text-sm sm:text-lg mt-[20px]">podrás crear perfiles para las personas que también
              trabajen en tu
              tienda</p>
            <div class="flex justify-center mt-[40px]">
              <img src="../../../../assets/svg/Group usuario onboarding.svg" alt="">
            </div>
          </div>
        </ng-container>
        <!-- Item 3 -->
        <ng-container *ngIf="currentSlide === 3">
          <div class="text-center w-[440px]">
            <p class="font-bold lg:text-2xl text-sm sm:text-lg leading-normal">Te damos la bienvenida a la
              plataforma Back office de Soho</p>
            <p class="font-semibold text-sm sm:text-lg mt-[20px]">podrás manejar mas de una tienda en una sesión, no
              tienes
              necesidad de crear varias cuentas</p>
            <div class="flex justify-center mt-[40px]">
              <img src="../../../../assets/svg/Group House Onboarding.svg" alt="">
            </div>
          </div>
        </ng-container>

        <!-- Mostrar botones redondos como indicadores -->
        <div class=" flex justify-center mt-[54px]">
          <button title="Continuar" (click)="continue(1)" class="w-3 h-3 rounded-full mx-1 bg-gray-400"
            [ngClass]="{'bg-cyan-800': currentSlide === 1, 'bg-gray-400': currentSlide !== 1}">
          </button>

          <button title="Continuar" (click)="continue(2)" class="w-3 h-3 rounded-full mx-1"
            [ngClass]="{'bg-cyan-800': currentSlide === 2, 'bg-gray-400': currentSlide !== 2}"></button>
          <button title="Continuar" (click)="continue(3)" class="w-3 h-3 rounded-full mx-1 bg-gray-400"
            [ngClass]="{'bg-cyan-800': currentSlide === 3, 'bg-gray-400': currentSlide !== 3}"></button>
        </div>
        <!-- Slider controls -->
        <div class="flex justify-center mt-5">
          <!-- Mostrar el botón "Siguiente" solo si no es la última diapositiva -->
          <button class="px-4 py-2 text-black border-b-2 border-black" *ngIf="currentSlide < 3"
            (click)="nextSlide()">Saltar</button>

          <!-- Mostrar el botón "Comenzar" solo si es la última diapositiva -->

          <button
            class="w-[280px] h-12 px-4 py-3 bg-cyan-700 rounded-lg justify-center items-center gap-1.5 inline-flex  text-white hover:bg-cyan-800"
            *ngIf="currentSlide === 3" (click)="skipOnboarding()">Comenzar</button>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="center">
    <sho-spinner-ecommerce/>
  </div>
</ng-template>
