import {inject} from '@angular/core';
import {Router, CanActivateFn} from '@angular/router';
import {map} from "rxjs";
import {FirebaseService} from '@shared/services/firebase.service'

export const UserIsLoggedIn: CanActivateFn = () => {
  const urlLogin = inject(Router).createUrlTree(['/signin'])
  return inject(FirebaseService).isAuthenticatedUser()
      .pipe(map(isLogin => isLogin || urlLogin))
}

export const UserIsNotLoggedIn: CanActivateFn = () => {
  const urlNav = inject(Router).createUrlTree([`/shop/${sessionStorage.getItem('shop')}/product`])
  return inject(FirebaseService).isAuthenticatedUser()
      .pipe(map(isLogin => isLogin ? urlNav : true))
}
