<div class="flex">
  <button (click)="abrirMenu()" data-drawer-target="logo-sidebar" data-drawer-toggle="logo-sidebar"
          aria-controls="logo-sidebar" type="button"
          class="inline-flex items-center p-2 mt-2 ml-3 text-sm text-gray-500  lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200">
    <span class="sr-only">Open sidebar</span>
    <svg class="w-6 h-6" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd" fill-rule="evenodd"
            d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"></path>
    </svg>
  </button>
  <div class="w-full hidden justify-center mt-2">
    <a [routerLink]="getUrl('dashboard')" class="lg:hidden flex items-center w-40 mr-14">
      <img src="./../../../../assets/images/Logo soho Seller.png" class="" style="Width:142.3px;Height:60.55px"
           alt="soho Logo"/>
    </a>
  </div>
</div>

<aside [ngClass]="{'-translate-x-full': currentSlideMenu, '': !currentSlideMenu}" id="logo-sidebar"
       class="fixed top-0 left-0 z-40 w-64 h-screen transition-transform -translate-x-full lg:translate-x-0"
       aria-label="Sidebar">
  <div class="w-[230px] h-full overflow-y-auto bg-white dark:bg-gray-800">
    <div class=" flex justify-end" [ngClass]="{'hidden': currentSlideMenu, '': !currentSlideMenu}">
      <button (click)="abrirMenu()" type="button" data-drawer-hide="drawer-navigation" aria-controls="drawer-navigation"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 top-2.5 right-2.5 inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white">
        <svg aria-hidden="true" class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20"
             xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"></path>
        </svg>
        <span class="sr-only">Close menu</span>
      </button>
    </div>

    <a [routerLink]="getUrl('product')" class="flex items-center w-full p-7">
      <img src="./../../../../assets/images/Logo soho Seller.png" class="" style="Width:142.3px;Height:60.55px"
           alt="soho Logo"/>
    </a>
    <div class="flex items-center w-full p-7">
      <p class="font-lato font-bold leading-6 text-center w-full">{{getRoleName}}</p>
    </div>
    <ul class="space-y-2 font-medium">

      <li [shoRole]="[roleList.ADMIN]">
        <a routerLinkActive="active-link" [routerLink]="getUrl('profile')"
           class="newHeigth flex items-center w-full p-2 text-base text-gray-900 transition duration-75 group hover:bg-gray-100"
           aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('profile')" class="w-5 h-5 text-gray-800"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 14 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M7 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7Zm-2 3h4a4 4 0 0 1 4 4v2H1v-2a4 4 0 0 1 4-4Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Perfiles</span>
        </a>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a [ngClass]="{'active-link-father': isActiveRoute([routes.PRODUCT, routes.PENDING])}"
           id="productos-id-drop"
           aria-controls="dropdown-product"
           data-collapse-toggle="dropdown-product"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group cursor-pointer">
          <svg routerLinkActive="active-linkSvg" class="w-5 h-5 text-gray-800" aria-hidden="true"
               xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M12 9V4a3 3 0 0 0-6 0v5m9.92 10H2.08a1 1 0 0 1-1-1.077L2 6h14l.917 11.923A1 1 0 0 1 15.92 19Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Productos</span>
          <svg *ngIf="!isActiveRoute([routes.PRODUCT, routes.PENDING])" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4"/>
          </svg>
          <svg *ngIf="isActiveRoute([routes.PRODUCT, routes.PENDING])" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.5L10.1772 7.67715C10.0793 7.5793 9.92067 7.5793 9.82283 7.67715L5 12.5" stroke="#146D81" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </a>
        <ul id="dropdown-product" class="hidden">
          <li>
            <a routerLinkActive="active-link-child"
               [routerLink]="getUrl(routes.PRODUCT)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Catálogo</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active-link-child"
               [routerLink]="getUrl(routes.PENDING)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Pendientes</span>
            </a>
          </li>
        </ul>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a [ngClass]="{'active-link-father': isActiveRoute([routes.ORDERS, routes.RETURNS])}" aria-controls="multiple-level" data-collapse-toggle="multiple-level"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group cursor-pointer">
          <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 18 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="M6 15a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm0 0h8m-8 0-1-4m9 4a2 2 0 1 0 0 4 2 2 0 0 0 0-4Zm-9-4h10l2-7H3m2 7L3 4m0 0-.792-3H1"/>
          </svg>
          <span routerLinkActive="active-linkSvg" class="flex-1 ml-3 text-left whitespace-nowrap">Ventas</span>
          <svg *ngIf="!isActiveRoute([routes.ORDERS, routes.RETURNS])" class="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m1 1 4 4 4-4"/>
          </svg>
          <svg *ngIf="isActiveRoute([routes.ORDERS, routes.RETURNS])" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 12.5L10.1772 7.67715C10.0793 7.5793 9.92067 7.5793 9.82283 7.67715L5 12.5" stroke="#146D81" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </a>
        <ul id="multiple-level" class="hidden">
          <li>
            <a routerLinkActive="active-link-child" [routerLink]="getUrl(routes.ORDERS)"
               class="newHeigth flex items-centertext-gray-900 hover:bg-gray-100 group">
              <span style="align-self: center" routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal aling leading-[19.2px] text-base text-[#122B36] h-[19px]">Pedidos</span>
            </a>
          </li>
          <li>
            <a routerLinkActive="active-link-child" [routerLink]="getUrl(routes.RETURNS)"
               class="newHeigth flex items-center text-gray-900 hover:bg-gray-100 group">
              <span routerLinkActive="active-linkSvg"
                    class="flex-1 ml-10 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Solicitudes</span>
            </a>
          </li>
        </ul>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.OFERTAS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('ofertas')" class="w-5 h-5 text-gray-800"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 19 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"
                  d="M13.583 5.445h.01M8.86 16.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 10.31 1l5.734.007A1.968 1.968 0 0 1 18 2.983v5.5a.994.994 0 0 1-.316.727l-7.439 7.5a.975.975 0 0 1-1.385.001Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Ofertas</span>
        </a>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.MENSAJES)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl('mensajes')" class="w-5 h-5 text-gray-800"
               aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none"
               viewBox="0 0 20 18">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.6"
                  d="M5.5 6.5h.01m4.49 0h.01m4.49 0h.01M18 1H2a1 1 0 0 0-1 1v9a1 1 0 0 0 1 1h3v5l5-5h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1Z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Mensajes</span>
        </a>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.ESTADISTICAS)"
           class="newHeigth flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg routerLinkActive="active-linkSvg" [routerLink]="getUrl(routes.ESTADISTICAS)"
               xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
               stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round"
                  d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"/>
          </svg>
          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Estadísticas</span>
        </a>
      </li>

      <li [shoRole]="[roleList.ALL]">
        <a routerLinkActive="active-link" [routerLink]="getUrl(routes.MULTIVENDE)"
           class="newHeigth mb-20 flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <svg [routerLink]="getUrl(routes.MULTIVENDE)" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.96875 1.875H2.65625C2.22478 1.875 1.875 2.22478 1.875 2.65625V7.96875C1.875 8.40022 2.22478 8.75 2.65625 8.75H7.96875C8.40022 8.75 8.75 8.40022 8.75 7.96875V2.65625C8.75 2.22478 8.40022 1.875 7.96875 1.875Z" stroke="#146D81" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.3438 1.875H12.0312C11.5998 1.875 11.25 2.22478 11.25 2.65625V7.96875C11.25 8.40022 11.5998 8.75 12.0312 8.75H17.3438C17.7752 8.75 18.125 8.40022 18.125 7.96875V2.65625C18.125 2.22478 17.7752 1.875 17.3438 1.875Z" stroke="#146D81" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M7.96875 11.25H2.65625C2.22478 11.25 1.875 11.5998 1.875 12.0312V17.3438C1.875 17.7752 2.22478 18.125 2.65625 18.125H7.96875C8.40022 18.125 8.75 17.7752 8.75 17.3438V12.0312C8.75 11.5998 8.40022 11.25 7.96875 11.25Z" stroke="#146D81" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M17.3438 11.25H12.0312C11.5998 11.25 11.25 11.5998 11.25 12.0312V17.3438C11.25 17.7752 11.5998 18.125 12.0312 18.125H17.3438C17.7752 18.125 18.125 17.7752 18.125 17.3438V12.0312C18.125 11.5998 17.7752 11.25 17.3438 11.25Z" stroke="#146D81" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <span routerLinkActive="active-linkSvg"
                class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Multivende</span>
        </a>
      </li>

      <li>
        <a (click)="logout()" class="cursor-pointer flex items-center p-2 text-gray-900 hover:bg-gray-100 group">
          <img src="./../../../../../assets/svg/Logout.svg" class="" alt="soho Logo"/>
          <span class="flex-1 ml-3 font-normal leading-[19.2px] text-base text-[#122B36] h-[19px]">Cerrar sesión</span>
        </a>
      </li>

    </ul>
  </div>
</aside>
