<div class="upload">
  <input #advancedFileInput [multiple]="multiple" (change)="onFileSelect($event)" class="hidden" type="file" accept=".csv">
  <div *ngIf="uploadedFiles.length <= 0" class="w-full lg:w-[100%] xl:w-[74%] h-[42px] flex">
    <div class="w-[250px] h-full rounded-lg bg-[#146D81] flex items-center justify-center">
      <button class="text-center text-white text-[16px] font-medium leading-[24px]" (click)="advancedFileInput.click()"
        [disabled]="multiple === false && uploadedFiles.length === 1">Seleccionar archivo</button>
    </div>
    <div class="p-3 bg-[#FFFFFF] flex justify-start items-center w-[421px] text-[#122B36] text-[16px] font-normal leading-[20px]">Sin archivos seleccionados</div>
  </div>

  <div *ngFor="let file of uploadedFiles;index as i" class="w-full lg:w-[100%] xl:w-[74%] h-[42px] flex">
    <div class="w-[250px] h-full rounded-lg bg-[#ADB4C2] flex items-center justify-center">
      <button class="text-center text-white text-[16px] font-medium leading-[24px]">Archivo seleccionado</button>
    </div>
    <div [title]="file.name" class="p-3 bg-[#FFFFFF] flex justify-between items-center w-[421px] text-[#122B36] text-[16px] font-normal leading-[20px]">
    <div class="w-[90%] flex">
      <p class="mr-2 font-normal text-base leading-[20px]">{{file.name | wrapText: 20}}</p>
      <p class="font-bold text-base leading-[20px]">({{file.size | shortNumber}})</p>
    </div>
    <div class="w-[10%] cursor-pointer flex justify-end" (click)="removeFile(i)">
      <img src="../../../../../assets/svg/Cancel.svg" />
    </div>
  </div>
  </div>
  <div class="mt-[8px] mb-[20px]">
    <span class="text-french-blue-500 text-[14px] font-normal leading-[22px]">*El archivo admitido para la carga es en formato CSV  </span>
    <span class="text-gray-400 text-[14px] font-normal leading-[22px]">(Ej. PIC/ejemplo.csv)</span>
  </div>
</div>
