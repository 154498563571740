export enum ROUTES_APP {
  DASHBOARD = 'dashboard',
  ORDERS = 'orders',
  RETURNS = 'returns',
  PRODUCT = 'product',
  PENDING = 'pending',
  PROFILE = 'profile',
  OFERTAS = 'ofertas',
  MENSAJES = 'mensajes',
  ESTADISTICAS = 'estadisticas',
  SIGNIN = 'signin',
  MULTIVENDE = 'multivende',
}
