<section class="modal-shop-selector bg-white rounded-xl min-h-[500px]">
  <div class="modal-shop-selector__container">
    <section class="mb-6">
      <label class="modal-shop-selector__title">Selecciona la tienda que quieres visualizar</label>
    </section>
    <section class="modal-shop-selector__search">
      <div class="relative w-full mt-3 md:mt-0 md:w-[446px] h-[48px] border-[#122B36] border-2 rounded-lg bg-white">
        <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg class="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
               fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input title="Buscar" type="text" id="search" name="filtro"
               class="w-full h-full block p-2.5 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500"
               [(ngModel)]="shopSearch"
               [placeholder]="'Buscar por el nombre de tu tienda'">
      </div>
    </section>
    <ng-container *ngIf="!isLoading; else loading">
      <section *ngIf="shops.length; else notFound" class="modal-shop-selector__list">
        <div class="modal-shop-selector__item"
             *ngFor="let shop of shops"
             (click)="selectShop(shop.id)">
          <img title="Tienda" class="modal-shop-selector__item--img"
               [src]="shop.src">
          <label class="modal-shop-selector__name">{{shop.name}}</label>
        </div>
      </section>
    </ng-container>
  </div>
</section>

<ng-template #loading>
  <div class="center">
    <sho-spinner-ecommerce/>
  </div>
</ng-template>

<ng-template #notFound>
  <div class="center">
    <sho-not-found/>
  </div>
</ng-template>
