import {Component, DestroyRef, HostListener, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {RequestStatus} from '@shared/interfaces/request-status.model';
import {faCartShopping} from '@fortawesome/free-solid-svg-icons';
import {finalize, interval, take} from 'rxjs';
import {SessionService} from "@shared/services/session.service";
import {Dialog} from "@angular/cdk/dialog";
import {ModalShopSelectorComponent} from "@shared/components/modal-shop-selector/modal-shop-selector.component";
import {ShopService} from "@shared/services/shop.service";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {ROUTES_APP} from "../../../app.routes";


@Component({
  selector: 'app-on-boarding',
  templateUrl: './on-boarding.component.html',
  styleUrls: ['./on-boarding.component.css']
})
export class OnBoardingComponent implements OnInit {
  currentSlide = 1;

  protected isLoading: boolean = false;

  constructor(
    private _dialog: Dialog,
    private _router: Router,
    private _sessionService: SessionService,
    private _shopService: ShopService,
    private readonly _destroyRef: DestroyRef
  ) {
    interval(5000).subscribe(() => {
      this.incrementarContador();
    });
  }

  ngOnInit(): void {
    const onBoardingWasVisited = localStorage.getItem('onBoardingWasVisited')
    if (onBoardingWasVisited) {
      this.goToDashboard()
    }
  }

  private incrementarContador() {
    if (this.currentSlide < 3) {
      this.currentSlide++;
    }
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (this.currentSlide == 1) {
      if (event.key === 'ArrowLeft') {
        this.currentSlide = 1;
      }
      if (event.key === 'ArrowRight') {
        this.currentSlide = 2;
      }
    } else {
      if (this.currentSlide == 2) {

        if (event.key === 'ArrowLeft') {
          this.currentSlide = 1;
        }
        if (event.key === 'ArrowRight') {
          this.currentSlide = 3;
        }
      } else {
        if (this.currentSlide == 3) {

          if (event.key === 'ArrowLeft') {
            this.currentSlide = 2;
          }
          if (event.key === 'ArrowRight') {
            this.goToDashboard();
          }
        }
      }
    }
  }

  status: RequestStatus = 'init';
  faCartShopping = faCartShopping


  nextSlide() {
    this.goToDashboard()
  }

  skipOnboarding() {
    this.goToDashboard()
  }

  continue(number: any) {
    this.currentSlide = number;
  }

  goToDashboard(): void {
    this._setCookieOnBoarding()
    this.isLoading = true
    this._shopService.getShops(true)
      .pipe(
        finalize(() => this.isLoading = false),
        takeUntilDestroyed(this._destroyRef)
      )
      .subscribe(res => {
        if (res.length === 1) {
          this._sessionService.setSessionShopId(res[0].id)
          this._router.navigateByUrl(`shop/${res[0].id}/${ROUTES_APP.PRODUCT}`).then()
        } else {
          this._dialog.open(ModalShopSelectorComponent, {
            maxWidth: '900px',
            disableClose: true
          }).closed
            .pipe(take(1))
            .subscribe((res) => {
              this._sessionService.setSessionShopId(res as string)
              this._router.navigateByUrl(`shop/${res}/${ROUTES_APP.PRODUCT}`).then()
            })
        }
      })
  }

  private _setCookieOnBoarding() {
    localStorage.setItem('onBoardingWasVisited', 'true')
  }
}
