export const RoleName = {
  ADMIN: 'ADMIN',
  SOHO_MANAGER: 'SOHO_MANAGER',
  SHOP_MANAGER: 'SHOP_MANAGER',
  ALL: '*'
} as const;

export const NameOfRole = {
  [RoleName.ADMIN]: 'Administrador',
  [RoleName.SOHO_MANAGER]: 'Ejecutivo Comercial',
  [RoleName.SHOP_MANAGER]: 'Ejecutivo de Tienda',
  ['*']: ''
}

export type RoleSho = 'ADMIN' | 'SOHO_MANAGER' | 'SHOP_MANAGER'
