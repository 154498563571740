export enum ROUTES_APP {
  EDIT_VARIANT_INDIVIDUAL = 'EditVariantindividual',
  EDIT_INDIVIDUAL_P = 'EditIndividualP',
  CREAR_VARIANTE = 'crear-variante',
  INDIVIDUAL = 'individual',
  INDIVIDUALP = 'individualP',
  REDIRECTION = 'redirection',
  ONBOARDING = 'onboarding',
  DASHBOARD = 'dashboard',
  ORDERS = 'orders',
  RETURNS = 'returns',
  PRODUCT = 'product',
  PENDING = 'pending',
  PROFILE = 'profile',
  OFERTAS = 'ofertas',
  MENSAJES = 'mensajes',
  ESTADISTICAS = 'estadisticas',
  SIGNIN = 'signin',
  MULTIVENDE = 'multivende',
}
