import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
  inject,
} from '@angular/core';
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from '@angular/material/tree';
import { Dialog } from '@angular/cdk/dialog';

import {productsPending } from '@shared/interfaces/productEcommerce.model';
import {ModalSettings, ModalIcon } from '../modal-generic/modal-model';
import { ModalGenericComponent } from '../modal-generic/modal-generic.component';
import { SessionService } from '@shared/services/session.service';
import { Router } from '@angular/router';

interface ExampleFlatNode {
  expandable: boolean;
  count: number;
  id: string;
  name: string;
  status:string;
  stock: number;
  marketplaceCategory: {name: string};
  variants: [];
  categoria: string;
  level: number;
  sku: string;
  price: string;
  idPadre: string;
  statusPadre: string;
  seleccionado: boolean;
}

@Component({
  selector: 'sho-table-tree-pending',
  templateUrl: './table-tree-pending.component.html',
  styleUrls: ['./table-tree-pending.component.scss']
})
export class TableTreePendingComponent implements OnChanges{
  private _sessionService = inject(SessionService)
  constructor(
    public dialog: Dialog,
    private router: Router,
  ){

  }

  menuAbiertos(data:any) {
    data.menuAbierto = true;
  }

  menuCerrados(data:any) {
    data.menuAbierto = false;
  }

  @Input() productosSeleccionados: productsPending[] = [];
  @Input() treeData: productsPending[] = [];
  @Output() selectionChange = new EventEmitter<productsPending[]>();
  @Output() seleccionadoTodo = new EventEmitter<boolean>();
  @Output() eliminarProducto = new EventEmitter<any>();

  displayedColumns: string[] = [
    'select',
    'name',
    'status',
    'categoria',
    'precio',
    'stock',
    'Atributos'
  ];
  selection = new SelectionModel<productsPending>(true, []);

  private transformer = (node: productsPending, level: number) => {
    return {
      expandable: !!node.variants && node.variants.length > 0,
      id: node.id,
      count: node.count,
      name: node.name,
      variants: node.variants,
      sku: node.sku,
      price: node.price,
      stock: node.stock,
      marketplaceCategory: node.marketplaceCategory,
      categoria: node.categoria,
      status: node.status,
      idPadre: node.idPadre,
      statusPadre: node.statusPadre,
      level: level,
      seleccionado: false,
    };
  };

  treeControl = new FlatTreeControl<ExampleFlatNode>(
    (node) => node.level,
    (node) => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    this.transformer,
    (node) => node.level,
    (node) => node.expandable,
    (node) => node.variants
  );

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;

  ngOnChanges(changes: SimpleChanges) {
    //console.log(this.productosSeleccionados)
    if (changes['treeData']) {
      this.dataSource.data = this.treeData;

    }
    this.markSelectedProducts()
    //console.log(this.dataSource.data)
  }

  markSelectedProducts() {
    this.treeControl.dataNodes.forEach((node) => {
      //console.log(node)
      node.seleccionado = this.productosSeleccionados.some((producto) => producto.id === node.id)
                          || this.productosSeleccionados.some((producto) => producto.idPadre === node.idPadre);
      //console.log(node.seleccionado)
    });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numTotalNodes = this.treeControl.dataNodes.length;
    const numProductNodes = this.treeControl.dataNodes.filter(node => node.level === 0).length;
    return numSelected === numTotalNodes - numProductNodes;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      // Si ya están seleccionados todos los nodos, deseleccionar todos los nodos
      this.selection.clear();
    } else {
      // Si no todos los nodos están seleccionados, seleccionar todos los nodos, excepto los del nivel 0 (productos)
      this.selection.select(...this.treeControl.dataNodes.filter(node => node.level > 0));
    }

    this.selectionChange.emit(this.selection.selected);
    this.seleccionadoTodo.emit(this.isAllSelected());
  }

  toggleSubproducts(node: ExampleFlatNode) {
    // Obtener todos los descendientes del nodo
    const descendants = this.treeControl.getDescendants(node);

    if (descendants.length === 0) {
      // Si el nodo no tiene descendientes, simplemente alternar su selección
      this.selection.toggle(node);
    } else {
      // Si el nodo tiene descendientes, determinar el estado de la selección
      const allSelected = descendants.every((child) =>
        this.selection.isSelected(child)
      );
      if (allSelected) {
        // Si todos los descendientes están seleccionados, deseleccionarlos
        this.selection.deselect(...descendants);
      } else {
        // Si no todos los descendientes están seleccionados, seleccionarlos
        this.selection.select(...descendants);
      }
    }

    // Emitir el evento `selectionChange` con los elementos seleccionados
    this.selectionChange.emit(this.selection.selected);
    this.seleccionadoTodo.emit(this.isAllSelected());
  }

  allSelectedTree(node: ExampleFlatNode) {
    //console.log(node)
    const descendants = this.treeControl.getDescendants(node);

    if (!descendants.length) {
      return this.selection.isSelected(node);
    }

    return descendants.every((child) => this.selection.isSelected(child));
  }

  indeterminateTree(node: ExampleFlatNode) {
    const descendants = this.treeControl.getDescendants(node);

    if (!descendants.length) {
      return false;
    }

    const desct = descendants.every((child) =>
      this.selection.isSelected(child)
    );

    const desctSell = descendants.some((child) =>
      this.selection.isSelected(child)
    );

    return !desct && desctSell;
  }

  checkboxLabel(row?: productsPending): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.id + 1
    }`;
  }


  editar(data: any) {
    //si el producto tiene propiedad idPadre es porque es un hijo.
    if(data.idPadre){
      this.router.navigateByUrl(this.getUrl(`pending/individual/${data.id}/individualP/${data.idPadre}`))
    }else{
      this.router.navigateByUrl(this.getUrl(`pending/individualP/${data.id}`))
    }
    //console.log(data)
    //[routerLink]="getUrl('product/individual', data.id)"
  }

  openDialogSuccess (icon:ModalIcon, titulo:string, texto?: string): void {
    const settings:ModalSettings = {
      tittle: titulo,
      paragraph: texto,
      paragraphSize:"w-[335px]",
      icon: icon,
    }
    const dialogRef = this.dialog.open(ModalGenericComponent, {
      disableClose: true,
      width: '450px',
      data: settings,
    });
  }

  eliminar(idProduct: any) {
    this.eliminarProducto.emit(idProduct);
    //console.log(idProduct);
    //this.openDialogSuccess(ModalIcon.Success, "El producto ha sido eliminado con éxito");
  }

  getUrl(url: string) {
    return `/shop/${this._sessionService.getShopIdStore()}/${url}`
  }

}
